<script>
export default {
    name:"KickbackConfirm",
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header: {
                mainTitle: '转入成功',
            },
        }
    },
    methods: {
        //返回
        left_click() {
            this.$router.push("/home");
        },
    },
}
</script>
<template>
    <div id="kickback-confirm" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <mt-header title="转入成功" style="height:0.88rem;font-size: 0.35rem;">
                <mt-button icon="back" slot="left" @click="left_click"></mt-button>
            </mt-header>
            <img class="yesimg" src="@@/assets/images2/user/yes.png" />
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <i class="icon icon-success"></i>
        </template>
        <p class="text">转入成功</p>
        <p @click="left_click" class="button">完 成</p>
    </div>
</template>
<style lang="scss" scoped type="text/css">
#kickback-confirm {

    &.template-3 {

        .text {
            color: #666666;
        }

        .button {
            width: 84%;
            line-height: 1;
            border-radius: .14rem;
            font-size: .34rem;
            height: auto;
            margin:0 auto;
            background-color: #EC2829;
            padding: .275rem 0;
        }

        .icon {

            &.icon-success {
                background-color: #F35959;
            }
        }
    }

    .icon {
        width: 2rem;
        height: 2rem;
        display: block;
        margin: 1rem auto .5rem;
        background-color: #eeeeee;
        border-radius: 100%;

        &.icon-success {
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('/static/assets/images/personal/icons/success-right.png');
        }
    }
}
.yesimg{
    width:26%;
    margin:25% 37% 5% 37%;
}
.text{
    width:100%;
    font-size: 0.4rem;
    text-align: center;
    margin-bottom:10%;
}
.button{
    width:80%;
    margin:0 10%;
    height:1rem;
    line-height:1rem;
    background:#3985E1;
    color:#FFF;
    font-size:0.4rem;
    border-radius: 0.2rem;
    text-align: center;
}
</style>
